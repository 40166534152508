import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import SEO from '../components/SEO';
import { underline, whiteAlpha1, fg } from '../styles/color';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  justify-content: center;
  margin: 0 2rem;
`;

const Title = styled.h1`
  border-bottom: 1px solid ${underline};
  display: inline-block;
  text-align: center;
`;

const Description = styled.p`
  line-height: 2em;
  text-align: center;
`;

const HomePageLink = styled(Link)`
  background-color: ${whiteAlpha1};
  border-radius: 1em;
  color: ${fg};
  display: inline-block;
  padding: 0 0.5em;
  text-decoration: underline;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: ${underline};
  }
`;

export default () => (
  <>
    <SEO title="Error 404 - Page Not Found" />
    <Container>
      <Title>Well... this is embarassing!</Title>
      <Description>You&apos;ve entered the void...</Description>
      <Description>
        Why don&apos;t you head back to the <HomePageLink to="/">homepage</HomePageLink> instead?
      </Description>
    </Container>
  </>
);
